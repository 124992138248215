export default [
  {
    title: 'Homeroom',
    key: 'Homeroom',
    icon: 'fe fe-bookmark',
    children: [
      {
        title: 'Personal Data',
        key: 'Personal Data Homeroom Teacher',
        url: '/teacher/homeroom/personal-data',
      },
      {
        title: 'Reports',
        key: 'Reports Homeroom Teacher',
        url: '/teacher/homeroom/report',
      },
    ],
  },
]
