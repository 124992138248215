<template>
  <a-layout-sider
    :width="settings.leftMenuWidth"
    :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView"
    @collapse="onCollapse"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width: settings.isMenuCollapsed && !settings.isMobileView ? '80px' : settings.leftMenuWidth + 'px',
        height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
      }"
      class="mt-4"
    >
      <!-- <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <img src="/Logo-Albayan-Islamic-School.png" width="40" class="ml-2" alt="Clean UI" />
          <div :class="$style.name">{{ settings.logo }}</div>
          <div v-if="settings.logo === 'Clean UI Pro'" :class="$style.descr">Vue</div>
        </div>
      </div>-->
      <vue-custom-scrollbar
        :style="{
          height: settings.isMobileView || settings.isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 110px)',
        }"
      >
        <!-- {{menuData}} -->
        <a-menu
          forceSubMenuRender
          :inlineCollapsed="settings.isMobileView ? false : settings.isMenuCollapsed"
          :mode="'inline'"
          :selectedKeys="selectedKeys"
          :openKeys.sync="openKeys"
          @click="handleClick"
          @openChange="handleOpenChange"
          :inlineIndent="15"
          :class="$style.navigation"
        >
          <template v-for="(item, index) in menuData">
            <template v-if="!item.roles || item.roles.includes(user.role)">
              <a-menu-item-group :key="index" v-if="item.category">
                <template slot="title">{{ item.title }}</template>
              </a-menu-item-group>
              <item
                v-if="!item.children && !item.category"
                :menu-info="item"
                :styles="$style"
                :key="item.key"
              />
              <sub-menu v-if="item.children" :menu-info="item" :styles="$style" :key="item.key" />
            </template>
          </template>
        </a-menu>
      </vue-custom-scrollbar>
    </div>
  </a-layout-sider>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import find from 'lodash/find'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import menuGuruMapel from '@/services/menu/guruMapel'
import menuWalikelas from '@/services/menu/walikelas'
import menuMurid from '@/services/menu/murid'
import menuAdmin from '@/services/menu/admin'
import menuPembinaEkskul from '@/services/menu/pembinaEkskul'
import menuKurikulum from '@/services/menu/kurikulum'
import menuPrincipal from '@/services/menu/principal'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'menu-left',
  components: { vueCustomScrollbar, SubMenu, Item },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
    role() {
      return this.$store.state.user.role
    },
    path() {
      const { name } = this.$route
      let path = this.$route.path
      if (this.role.includes('guruMapel')) {
        path = '/teacher'
        switch (name) {
          case 'Detail Cbt Teacher':
            path = path + '/cbt'
            break
          case 'Result Cbt Teacher':
            path = path + '/cbt'
            break
          case 'Add Cbt Teacher':
            path = path + '/cbt'
            break
          case 'Cbt Supervision Teacher':
            path = path + '/cbt'
            break
          case 'Presences Assignments Teacher':
            path = path + '/assignments'
            break
          case 'Grading Assignments Teacher':
            path = path + '/assignments'
            break
          case 'Detail Homeroom Teacher':
            path = path + '/homeroom/report'
            break
          case 'Edit Schedule':
            path = path + '/schedule'
            break
          case 'Detail Schedule Teacher':
            path = path + '/schedule'
            break
          case 'ECA Supervisor Teacher Manage Students':
            path = path + '/eca-supervisor'
            break
          default:
            path = this.$route.path
            break
        }
      } else if (this.role.includes('murid')) {
        path = '/student'
        switch (name) {
          case 'Cbt Start Exam Student':
            path = path + '/cbt'
            break
          case 'Detail Subject Student':
            path = path + '/class'
            break
          case 'Detail Schedule Student':
            path = path + '/schedule'
            break
          default:
            path = this.$route.path
            break
        }
      } else if (this.role.includes('admin')) {
        path = '/admin'
        switch (name) {
          case 'Add Teacher Admin':
            path = path + '/teacher'
            break
          case 'Detail Teacher Admin':
            path = path + '/teacher'
            break
          case 'Schedule Teacher Admin':
            path = path + '/teacher'
            break
          case 'Detail Student Admin':
            path = path + '/student'
            break
          case 'Add Student Admin':
            path = path + '/student'
            break
          case 'Detail Class Admin':
            path = path + '/class'
            break
          case 'Extracurricular Detail Admin':
            path = path + '/extracurricular'
            break
          default:
            path = this.$route.path
            break
        }
      }
      return path
    },
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    this.menu()
    this.setSelectedKeys()
  },
  data() {
    return {
      menuData: [],
      selectedKeys: [],
      openKeys: [],
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    '$route'() {
      this.setSelectedKeys()
    },
  },
  methods: {
    onCollapse: function (collapsed, type) {
      const value = !this.settings.isMenuCollapsed
      this.$store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
    },
    handleClick(e) {
      // if (e.key === 'settings') {
      //   this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
      //   return
      // }
      const value = !this.settings.isMobileMenuOpen
      this.$store.commit('CHANGE_SETTING', { setting: 'isMobileMenuOpen', value })
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
    },
    handleOpenChange(openKeys) {
      store.set('app.menu.openedKeys', openKeys)
      this.openKeys = openKeys
    },
    setSelectedKeys() {
      const pathname = this.path
      // console.log(this.path, this.selectedKeys, 'setselectedKeys')
      // console.log(pathname, 'ini path')
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          // console.log(flattenedItems, item, key, 'flattended items')
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      // console.log(flattenItems)
      const selectedItem = find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      // console.log(this.selectedKeys, selectedItem, 'before')
      this.selectedKeys = selectedItem ? [selectedItem.key] : []
      // console.log(this.selectedKeys, 'after')
    },
    menu() {
      if (this.role.includes('guruMapel')) {
        this.menuData = menuGuruMapel
        if (this.role.includes('waliKelas')) {
          this.menuData = this.menuData.concat(menuWalikelas)
        }
        if (this.role.includes('pembinaEkskul')) {
          this.menuData = this.menuData.concat(menuPembinaEkskul)
        }
        if (this.role.includes('kurikulum')) {
          this.menuData = this.menuData.concat(menuKurikulum)
        }
        if (this.role.includes('kepalaSekolah')) {
          this.menuData = this.menuData.concat(menuPrincipal)
        }
      } else if (this.role.includes('murid')) {
        this.menuData = menuMurid
      } else if (this.role.includes('admin')) {
        this.menuData = menuAdmin
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
