<template>
  <a-menu v-model="currentMenu" class="topbarTeacher" mode="horizontal">
    <a-menu-item key="Dashboard Teacher" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Dashboard Teacher' }">
        <a-icon type="home" />Dashboard
      </router-link>
    </a-menu-item>
    <a-menu-item key="Schedule Teacher" class="subMenuDetail" id="menu-schedule-teacher">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Schedule Teacher' }">
        <a-icon type="calendar" />Schedule
      </router-link>
    </a-menu-item>
    <a-menu-item key="Planning Teacher" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Planning Teacher' }">
        <a-icon type="appstore" />Planning
      </router-link>
    </a-menu-item>
    <!-- <a-menu-item key="Assignments Teacher" class="subMenuDetail">
      <router-link
        :target="currentRouteIsDetailSchedule && isLive? '_blank' : '_self'"
        :to="{ name : 'Assignments Teacher'}"
      >
        <a-icon type="inbox" />Assignments
      </router-link>
    </a-menu-item>-->
    <a-sub-menu class="submenu">
      <span slot="title" class="submenu-title-wrapper">
        <a-icon type="solution" />Assesments
      </span>
      <a-menu-item key="Assignments Teacher" class="subMenuDetail">
        <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
          :to="{ name: 'Assignments Teacher' }">
          <a-icon type="inbox" />Assignments
        </router-link>
      </a-menu-item>
      <a-menu-item key="Reports Teacher" class="subMenuDetail">
        <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
          :to="{ name: 'Reports Teacher' }">
          <a-icon type="reconciliation" />Reports
        </router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu v-if="role.includes('waliKelas')" class="submenu">
      <span slot="title" class="submenu-title-wrapper">
        <!-- <a-icon type="solution" />Homeroom -->
        <i class="fas fa-child mr-2"></i>Homeroom
      </span>
      <a-menu-item key="Personal Data Homeroom Teacher" class="subMenuDetail">
        <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
          :to="{ name: 'Personal Data Homeroom Teacher' }">
          <a-icon type="inbox" />Personal Data
        </router-link>
      </a-menu-item>
      <a-menu-item key="Reports Homeroom Teacher" class="subMenuDetail">
        <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
          :to="{ name: 'Reports Homeroom Teacher' }">
          <a-icon type="reconciliation" />Reports
        </router-link>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item key="Cbt Teacher" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'" :to="{ name: 'Cbt Teacher' }">
        <a-icon type="file-protect" />CBT
      </router-link>
    </a-menu-item>
    <a-menu-item v-if="role.includes('guruAdmin')" key="Admin Teacher" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Assignments Teacher' }">
        <a-icon type="key" />Admin
      </router-link>
    </a-menu-item>
    <a-menu-item v-if="role.includes('kurikulum')" key="Curriculum Teacher" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Curriculum Teacher' }">
        <a-icon type="container" />Curriculum
      </router-link>
    </a-menu-item>
    <a-menu-item v-if="role.includes('kepalaSekolah')" key="Principal Teacher" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Principal Teacher' }">
        <a-icon type="container" />Principal
      </router-link>
    </a-menu-item>
    <a-menu-item v-if="role.includes('pembinaEkskul')" key="Pembina Ekskul Teacher" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'ECA Supervisor Teacher' }">
        <a-icon type="rocket" />Extracurricular Spv
      </router-link>
    </a-menu-item>
    <!-- <a-menu-item v-if="role.includes('guruBK')" key="Pembina Ekskul Teacher" class="subMenuDetail"> -->
    <a-menu-item v-if="true" key="Counseling Teacher" class="subMenuDetail">
      <router-link :target="currentRouteIsDetailSchedule && isLive ? '_blank' : '_self'"
        :to="{ name: 'Counseling Teacher' }">
        <a-icon type="smile" />Counseling
      </router-link>
    </a-menu-item>
  </a-menu>
</template>
<script>
export default {
  created() {
    // console.log(this.$router.history.current)
    // this.$store.commit('menu/SET_STATE', {
    //   currentMenu: ['Cbt Teacher'],
    // })
    if (this.$router.history.current.name.split(' ').includes('Schedule')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Schedule Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Planning')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Planning Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Assignments')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Assignments Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Homeroom')) {
      const role = this.$router.history.current.meta.role
      console.log(role)
      if (role.includes('kurikulum') && this.role.includes('kurikulum')) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: ['Curriculum Teacher'],
        })
      } else if (role.includes('kepalaSekolah') && this.role.includes('kepalaSekolah')) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: ['Principal Teacher'],
        })
      } else {
        if (this.$router.history.current.name.split(' ').includes('Detail')) {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Reports Homeroom Teacher'],
          })
        } else if (this.$router.history.current.name.split(' ').includes('Personal')) {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Personal Data Homeroom Teacher'],
          })
        }
      }
    } else if (this.$router.history.current.name.split(' ').includes('Cbt')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Cbt Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Reports')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Reports Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('ECA')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Pembina Ekskul Teacher'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Counseling')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Counseling Teacher'],
      })
    } else {
      // console.log(this.$router.history.current)
      this.$store.commit('menu/SET_STATE', {
        currentMenu: [this.$router.history.current.name],
      })
    }
  },
  computed: {
    currentMenu: {
      get: function () {
        return this.$store.state.menu.currentMenu
      },
      set: function (value) {
        if (this.isLive) {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Schedule Teacher'],
          })
        } else {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: value,
          })
        }
      },
    },
    isLive() {
      return this.$store.state.live.isLive
    },
    currentRouteIsDetailSchedule() {
      return this.$route.name.match(/Detail Schedule/gi) !== null
    },
    role() {
      return this.$store.state.user.role
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
